import Reveal from '@stimulus-components/reveal'

export default class extends Reveal {
  static targets = ['item', 'revealer']

  connect () {
    super.connect()

    // Apply selection on connection if revealer is selected
    this.applySelection()
  }

  applySelection () {
    const selectedRadioButton = this.revealerTargets.find(radio => radio.checked)
    if (selectedRadioButton) {
      this.show()
    }
  }

  hide () {
    super.hide()
  }

  show () {
    super.show()
  }
}
